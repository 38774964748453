import { Button, Center, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { FC } from "react";
import ViewCodes from "./ViewCodesTable";
import CreateCodes from "./CreateCodes";
import BlockCode from "./BlockCode";

const CodeActions: FC = () => {
  const {
    isOpen: isViewCodesModalOpen,
    onOpen: onViewCodesModalOpen,
    onClose: onViewCodesModalClose } = useDisclosure();

  const {
    isOpen: isCreateCodesModalOpen,
    onOpen: onCreateCodesModalOpen,
    onClose: onCreateCodesModalClose } = useDisclosure();

  const {
    isOpen: isBlockCodeModalOpen,
    onOpen: onBlockCodeModalOpen,
    onClose: OnBlockCodeModalClose } = useDisclosure();

  return (
    <>
      <HStack>
        <Button variant={"outline"} onClick={onCreateCodesModalOpen}>Create</Button>
        <Button variant={"outline"} onClick={onViewCodesModalOpen}>View</Button>
        <Button variant={"outline"} onClick={onBlockCodeModalOpen}>Block a Code</Button>
      </HStack>

      <Modal isOpen={isViewCodesModalOpen} size={"full"} onClose={onViewCodesModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Center>View Codes</Center></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ViewCodes codes={[]} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCreateCodesModalOpen} size={"full"} onClose={onCreateCodesModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Center>Create Codes</Center></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreateCodes />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isBlockCodeModalOpen} size={"lg"} onClose={OnBlockCodeModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Center>Block Code</Center></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BlockCode code="" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CodeActions;
