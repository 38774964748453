import { Stack, Heading, FormControl, FormLabel, Input, Button, Container, useToast } from "@chakra-ui/react";
import { FC, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";

const SignInForm: FC = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const toast = useToast();

  const doSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        toast({
          id: "signin-success-toast",
          duration: 5000,
          title: user.email + " signed in successfully",
          status: "success",
          isClosable: true,
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast({
          duration: 5000,
          title: "Error signing in: please check your credentials",
          status: "error",
          isClosable: true,
        });
        console.log(errorCode, errorMessage)
      });

  }

  return (
    <Container maxWidth={"sm"}>
      <Stack spacing={4} >
        <Heading fontSize={'1xl'}>Sign in to your account</Heading>
        <Stack spacing={4}>
          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
          </FormControl>
          <Stack spacing={10}>
            <Button onClick={doSignIn}>
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default SignInForm;
