import { Button, Container, FormControl, FormLabel, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Tooltip, VStack, useToast } from "@chakra-ui/react";
import { FC, useState } from "react";
import { api } from "../../services/api";
import { Code } from "../../index.types";
import ViewCodes from "./ViewCodesTable";

const CreateCodes: FC = () => {
  const toast = useToast();
  const [codes, setCodes] = useState<Code[]>([]);
  const [count, setCount] = useState<number>(1);
  const [batchName, setBatchName] = useState<string>("");

  const handleCreateClicked = async () => {
    api.createCodes(count, batchName).then(codesResponse => {
      setCodes(codesResponse.data);
      toast({
        duration: 3000,
        title: "Created " + count + " codes",
        status: "success",
        isClosable: true,
        position: "top",
      });
    }).catch((error) => {
      let errorMessage = "";
      if (error.isAxiosError) {
        errorMessage = error.response.data;
      }
      toast({
        duration: 5000,
        title: "There was an error creating codes. " + errorMessage,
        status: "error",
        isClosable: true,
        position: "top",
      });
      console.log(error);
    });
  }

  return (
    <>
      {codes.length == 0 && (
        <Container size={"sm"}>
          <VStack>

          <FormControl isRequired>
            <FormLabel>How many codes to create?</FormLabel>
            <NumberInput defaultValue={1} max={10} min={1} onChange={ (_, vNum) => setCount(vNum) }>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl isRequired>
            <Tooltip label="Give any random batch name, avoid repeating">
              <FormLabel>Name of the batch (used to identify codes later)</FormLabel>
            </Tooltip>
            <Input placeholder='tango1' onChange={(e) => setBatchName(e.target.value)} />
          </FormControl>

          <Button variant={"outline"} size={"lg"} onClick={handleCreateClicked}>Create</Button>
          </VStack>
        </Container>
      )}
      {codes.length > 0 && (
        <ViewCodes codes={codes} />
      )}
    </>

  );
};

export default CreateCodes;
