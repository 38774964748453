import { FC, PropsWithChildren } from "react";
import {
  Center,
  Container,
  Flex,
  Image,
  Heading,
} from "@chakra-ui/react";

const Layout: FC<PropsWithChildren> = ({ children }) => {

  return (
    <Flex direction="column" h="100vh">
      <Container maxW="3xl" marginBottom="10px">
        <Center>
          <Image
            src="/tracy-logo.svg"
            height="70px"
            alt="Tracy logo"
            marginTop="5px"
            marginBottom="10px"
          />
        </Center>
        <Center>
          <Heading fontSize="2xl" textAlign={"center"}>
            Administration
          </Heading>
        </Center>
      </Container>
      <Flex direction="column" h="full" p={5} borderTop={"1px"} borderColor={"red.100"} >
        {children}
      </Flex>
    </Flex>
  );
};

export default Layout;
