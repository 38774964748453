import { Button, Container, Text, useToast } from "@chakra-ui/react";
import { signOut, User } from "firebase/auth";
import { FC } from "react";
import { auth } from "../../firebase";

type Props = {
  user: User
};

const UserDetails: FC<Props> = ({user}) => {
  const toast = useToast();

  const signOutUser = () => {
    signOut(auth)
      .then(() => {
        toast({
          id: "signout-toast",
          duration: 5000,
          title: user.email + " signed out successfully",
          status: "success",
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          id: "signout-error-toast",
          duration: 5000,
          title: "Error signing out " + user.email,
          status: "error",
          isClosable: true,
        });
      });
  };

  return (
    <Container textAlign={"center"}>
      <Text fontSize={"xs"}>User <strong>{user.email}</strong> is logged in.
      <Button size={"xs"} variant="ghost" onClick={signOutUser}>Logout</Button>
      </Text>
    </Container>
  );
};

export default UserDetails;
