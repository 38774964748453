import { Button, Center, Container, Text, FormControl, FormErrorMessage, FormLabel, Input, Tooltip, VStack, useToast, ButtonGroup } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { api } from "../../services/api";
import { LockIcon } from "@chakra-ui/icons";

const BlockCode: FC<{ code: string }> = ({ code }) => {

  const toast = useToast();

  const [codeInput, setCodeInput] = useState(code);

  const [formError, setFormError] = useState("");

  const [isConfirmation, setIsConfirmation] = useState(code !== "");

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if(codeInput.length != 10) {
      setFormError("Code must be 10 characters long");
    }
    else setFormError("");
  }, [codeInput]);

  const handleBlockCodeYesClick = () => {
    api.blockCode(codeInput).then(codesResponse => {
      console.log("Code block api response", codesResponse);
      toast({
        duration: 3000,
        title: "Successfully blocked code",
        status: "success",
        isClosable: true,
      });
      setIsSuccess(true);
    }).catch(error => {
      toast({
        duration: 10000,
        title: "There was an error blocking the code " + codeInput,
        status: "error",
        isClosable: true,
      });
      console.log(error);
    });
  }

  return (
    <Container size={"sm"}>
      {!isConfirmation && (
        <VStack>
          <FormControl isRequired isInvalid={formError != ""}>
            <Center>
              <Tooltip label="Code to be blocked">
                <FormLabel>Code</FormLabel>
              </Tooltip>
              <Input
                type="alphanumeric"
                placeholder='code123456'
                onChange={e => setCodeInput(e.target.value)}
                value={codeInput}
                size={"lg"}
                fontSize={"xl"}
                fontFamily={"monospace"}
                maxLength={10}
                htmlSize={10} width='auto'
              />
            </Center>
            <Center>
              <FormErrorMessage>{formError}</FormErrorMessage>
            </Center>
          </FormControl>
          <Button isDisabled={formError !== ""} variant={"outline"} size={"lg"} onClick={() => setIsConfirmation(true)}>Block</Button>
        </VStack>
      )}

      {isConfirmation && !isSuccess && (
        <VStack>
          <Text fontSize="md">Are you sure you want to block the following code?</Text>
          <Text fontSize="xl" fontFamily={"monospace"}>{codeInput}</Text>
          <ButtonGroup>
            <Button variant={"solid"} size={"lg"} onClick={handleBlockCodeYesClick}>Yes</Button>
            <Button variant={"outline"} size={"lg"} onClick={() => setIsConfirmation(false)}>No</Button>
          </ButtonGroup>
        </VStack>
      )}

      {isSuccess && (
        <VStack>
          <Text fontSize="md">Code successfully blocked</Text>
          <Text fontSize="xl" fontFamily={"monospace"}>{codeInput}</Text>
          <LockIcon />
        </VStack>
      )}
    </Container>
  );
};

export default BlockCode;
