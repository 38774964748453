import { Box, Center, Container, StackDivider, VStack } from "@chakra-ui/react";
import Layout from "../../components/admin/Layout";

import { auth } from "../../firebase";
import { useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";

import SignInForm from "../../components/admin/SignInForm";
import UserDetails from "../../components/admin/UserDetails";
import CodeActions from "../../components/admin/CodeActions";

const AdminIndexPage = () => {
  const [user, setUser] = useState<User|null>(null);

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, [])

  return (
    <Layout>
      <Container maxW="xl" minHeight="40%">
        <Box p={1} width={"full"}>
          {!user && (
            <SignInForm/>
          )}
        </Box>
        {user && (
          <VStack divider={<StackDivider borderColor='gray.200' />}>
            <Box p={1} width={"full"}>
              User
              <UserDetails user={user}/>
            </Box>
            <Box p={1} width={"full"}>
              Codes
              <Center>
                <CodeActions/>
              </Center>
            </Box>
          </VStack>
        )}
      </Container>
    </Layout>
  );
};

export default AdminIndexPage;
