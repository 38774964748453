import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { Code } from "../../index.types";
import BlockCodeButton from "./BlockCodeButton";
import { api } from "../../services/api";

type Props = {
  codes: Code[];
};
const ViewCodes: FC<Props> = ({codes}) => {
  const toast = useToast();

  const [codesInView, setCodesInView] = useState(codes);

  const fetchCodes = () => {
    api.getCodes().then(codesResponse => {
      setCodesInView(codesResponse.data);
    }).catch(error => {
      toast({
        duration: 3000,
        title: "There was an error getting codes from the server",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    });
  }

  useEffect(() => {
    if (codes.length == 0) {
      fetchCodes();
    }
  }, []);

  return (
    <TableContainer>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Code</Th>
            <Th>Created By</Th>
            <Th>Created Date</Th>
            <Th>First Used Date</Th>
            <Th>Expiry Date</Th>
            <Th>Times used</Th>
            <Th>Expired</Th>
            <Th>Batch Name</Th>
            {/* do not display actions when codes are passed to this component */}
            {codes.length == 0 && <Th>Actions</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {codesInView && codesInView.map(code =>
            <Tr key={code.code}>
              <Td fontFamily={"mono"} fontWeight={"bold"}>{code.code}</Td>
              <Td>{code.createdBy}</Td>
              <Td>{code.createdOn && new Date(parseInt(code.createdOn)).toDateString()}</Td>
              <Td>{code.firstUsedOn && new Date(parseInt(code.firstUsedOn)).toDateString()}</Td>
              <Td>{code.expiresOn && new Date(parseInt(code.expiresOn)).toDateString()}</Td>
              <Td>{code.usedCount}</Td>
              <Td>{code.expired ? "Yes" : "No"}</Td>
              <Td>{code.batchName}</Td>
              {codes.length == 0 && <Td><BlockCodeButton code={code.code} onActionClosed={() => fetchCodes()} /></Td> }
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ViewCodes;
