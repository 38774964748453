import { LockIcon } from "@chakra-ui/icons";
import { Center, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, IconButton } from "@chakra-ui/react";
import { FC } from "react";
import BlockCode from "./BlockCode";

const BlockCodeButton: FC<{ code: string, onActionClosed: () => void}> = ({ code, onActionClosed }) => {

  const {
    isOpen: isBlockCodeModalOpen,
    onOpen: onBlockCodeModalOpen,
    onClose: OnBlockCodeModalClose } = useDisclosure();

  const onModalClose = () => {
    console.log("modal is closed")
    OnBlockCodeModalClose();
    onActionClosed();
  }

  return (
    <>
      <IconButton
        variant="outline"
        aria-label={"Block"}
        icon={<LockIcon />}
        onClick={onBlockCodeModalOpen}
      />
      <Modal isOpen={isBlockCodeModalOpen} size={"lg"} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Center>Block Code</Center></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BlockCode code={code} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BlockCodeButton;
